import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import * as _topojson from "topojson";
import { Alert } from './entities/alert';
import { URL_API } from 'src/environments/environment';
import { TI } from './entities/ti';
import { VectorLayer } from './entities/vector-layer';
import { User } from './entities/user.entity';
import { AuthService } from './auth.service';
import * as _ from 'underscore';
import * as turf from '@turf/turf';
//import { AlertsFilter } from '../shared/interfaces/filter.interface';
import { LatLng } from 'leaflet';

const topojson = _topojson as any;

@Injectable()
export class ApiService {

  constructor(private authService: AuthService, private http: HttpClient) { }

  /**
   * Gets alerts
   */
  getAlerts(): Observable<Alert[]> {
    return this.http.get<Alert[]>(`${URL_API}/alertasro`);
  }

  /**
   * Gets alert by id
   */
  getAlertById(id: number): Observable<Alert> {
    return this.http.get<Alert>(`${URL_API}/alertasro/${id}`).pipe(
      map(response => Object.assign(new Alert(), response))
    );
  }

  getNextAlert(id: number): Observable<Alert> {
    return this.http.get<Alert>(`${URL_API}/alertas/consulta/next/${id}`).pipe(
      map(response => Object.values(response)[0]),
      map(alert => Object.assign(new Alert(), alert))
    );
  }


  getPreviousAlert(id: number): Observable<Alert> {
    return this.http.get<Alert>(`${URL_API}/alertas/consulta/previous/${id}`).pipe(
      map(response => Object.values(response)[0]),
      map(alert => Object.assign(new Alert(), alert))
    );
  }

  /**
   * Gets alert sources
   */
  getAlertSources(query: { territorial_category?: any, class_list?: string[], ti_list?: number[] } = null): Observable<any[]> {
    return this.http.post<any[]>(`${URL_API}/alertas/list/source`, query).pipe(
      map(response => {
        return response;
      })
    );
  }

  /**
   * Gets alert satellite type
   */
  getAlertSatellite(): Observable<any[]> {
    return this.http.get<any[]>(`${URL_API}/alertas/list/satellite`).pipe(
      map(response => {
        return response;
      })
    );
  }

  /**
   * Gets alert sizes
   */
  getAlertSizes(): Observable<any[]> {
    return this.http.get<any[]>(`${URL_API}/alertas/list/sizerange`).pipe(
      map(response => {
        return response;
      })
    );
  }

  /**
   * Gets alert types class
   */
  getAlertClasses(query: { territorial_category?: any, ti_list?: number[], source_list?: string[] } = null): Observable<any[]> {
    return this.http.post<any[]>(`${URL_API}/alertas/list/classes`, query).pipe(
      map(response => {
        return response.map(item => {
          item.source = item.source.replace('{', '').replace('}', '');
          item.source = (item.source as string).split(',').map(element => {
            if (element == 'ALERT') {
              element = 'SMDK';
            }
            return element;
          }).join(', ');
          return item;
        });
      })
    );
  }

  /**
   * Gets alert types class
   */
  getVectorLayers(query: { ti_list?: number[], class_list?: string[], source_list?: string[], buffer?: number } = null): Observable<VectorLayer[]> {
    return this.http.post<VectorLayer[]>(`${URL_API}/alertas/list/territorialcategory`, query).pipe(
      map(result => {

        const newResult = [];

        const resultByCategoria = _.groupBy(result, item => item.categoria);

        Object.keys(resultByCategoria).forEach(categoria => {

          const dataCategoria = resultByCategoria[categoria];

          if (dataCategoria[0].categoria == 'Unidades de Conservação - Estadual') {
            let elementCategoria = {
              id: 'uc:estadual',
              categoria: dataCategoria[0].categoria,
              name: 'Todas as UC Estaduais'
            };
            newResult.push(elementCategoria);
          }

          if (dataCategoria[0].categoria == 'Unidades de Conservação - Federal') {
            let elementCategoria = {
              id: 'uc:federal',
              categoria: dataCategoria[0].categoria,
              name: 'Todas as UC Federais'
            };
            newResult.push(elementCategoria);
          }

          dataCategoria.forEach(element => {
            newResult.push(element)
          });

        });

        return newResult;
      })
    );
  }

  /**
   * Gets alert layer
   */
  getVectorLayerById(id: any, categoria: string): Observable<any[]> {
    return this.http.post<any[]>(`${URL_API}/geometry/request`, {
      idCategoria: id,
      categoria: categoria
    }).pipe(
      map(result => {
        let geom = result.reduce((accum, item) => {
          return turf.union(accum, turf.feature(JSON.parse(item.geom)));
        }, turf.feature(JSON.parse(result[0].geom)));
        return geom;
      })
    )
  }

  /**
   * Gets ti geometry features
   *
   * @returns
   * @memberof ApiService
   */
  getGeomFeaturesTi(query: { territorial_category?: any, class_list?: string[], source_list?: string[] } = null): Observable<TI[]> {
    // const cache = localStorage.getItem('ti');
    // if (cache) {
    //   return of(JSON.parse(cache));
    // }
    return this.http.post<TI[]>(`${URL_API}/alertas/list/ti`, query, { responseType: 'json' }).pipe(
      // tap((response) => {
      //   try {
      //     localStorage.setItem('ti', JSON.stringify(response));
      //   } catch (e) {
      //     console.log(e);
      //   }
      // }),
      map(response => {
        return response;
      })
    );
  }

  /**
  * Gets alerts graph
  */
  // getAlertsGraphs(query: { idTI?: number, classe?: string, categoria?: string, idCategoria?: number } = null): Observable<any[]> {
  //   const httpParams = new HttpParams({
  //     fromObject: query as any,
  //   });
  //   return this.http.get<any[]>(`${URL_API}/alertas/graph/abs`, {
  //     params: httpParams
  //   }).pipe(
  //     debounceTime(400),
  //   );
  // }

  getChartDataClasses(query: {} = null): Observable<any[]> {
    return this.http.post<any[]>(`${URL_API}/alertas/graph2/abs`, query).pipe(
      debounceTime(400)
    );
  }

  getChartDataBySizes(query: {} = null): Observable<any[]> {
    return this.http.post<any[]>(`${URL_API}/alertas/graph2/range`, query).pipe(
      debounceTime(400)
    );
  }

  getChartDataByTerritory(query: {} = null): Observable<any[]> {
    return this.http.post<any[]>(`${URL_API}/alertas/graph2/territory`, query).pipe(
      debounceTime(400)
    );
  }

  getChartDataByTiBuffer(query: {} = null): Observable<any[]> {
    return this.http.post<any[]>(`${URL_API}/alertas/graph2/tibuffer `, query).pipe(
      debounceTime(400),
    );
  }

  updateUser(user: Partial<User>): Observable<any> {
    const { token = "" } = this.authService.getAuthorization();
    return this.http.post(`${URL_API}/user/update`, user, { responseType: 'text' as 'json' });
  }

  async getPointInfo(point: number[]): Promise<any> {
    return this.http.post<any>(`${URL_API}/inspector`, {
      lat: point[1],
      lng: point[0]
    }).toPromise();
  }

  async getPointHistory(latLng: L.LatLng): Promise<any> {
    return this.http.get<any>(`https://plataforma.brasil.mapbiomas.org/downloads/history_point?lat=${latLng?.lat}&lng=${latLng?.lng}`).toPromise();
  }

  async getPointFireHistory(latLng: L.LatLng): Promise<any> {
    return this.http.get<any>(`https://plataforma.brasil.mapbiomas.org/downloads/history_point_fire?lat=${latLng?.lat}&lng=${latLng?.lng}`).toPromise();
  }
}

export interface ServiceResponse<T> {
  data: T;
}
