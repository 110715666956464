<header class="l-header">
  <div class="l-header-main">
    <section class="l-header-main__logo">
      <a [routerLink]="['/home']">
        <img src="assets/img/kaninde/SMDK+font.svg" alt=""></a>
      <div></div>
    </section>
    <section class="l-header-main__content">
  <div id="progress-spinner">
      <mat-progress-spinner diameter=30 mode="indeterminate" color="accent" *ngIf="loadingService.isLoading | async;">
      </mat-progress-spinner>
    </div>
      <!-- icon and header text -->
      <div class="position-center">
        <div class="headerbox-bg">
          <div class="headerbox">
            <span class="content-title" id="grid-mod-header">
              <!-- <img
                class="icon-module"
                [src]="appModule.logo"
                [alt]="appModule.title"
              /> -->
              <img src="/assets/img/icon-menu/iconMenu_colorHovering/consulta_colorHovering.svg"
                alt="Módulo de Consulta" id="icon-module">
              <span class="item-linebox"></span>
              <span class="title-header">Módulo de Consulta</span>
              <span> {{appModules.title}}</span>
            </span>
          </div>
        </div>
      </div>
      <span>
        <ngx-avatar class="my-avatar user-avatar" [matMenuTriggerFor]="menu" [name]="authorization.user.name"
          size="40"></ngx-avatar>
        <mat-menu #menu="matMenu" xPosition="before">
          <button mat-menu-item (click)="openDialog('EmailComponent', 450)"><span class="title-btn">Alterar
              e-mail</span></button>
          <button mat-menu-item (click)="openDialog('PasswordComponent', 450)"><span class="title-btn">Alterar
              senha</span></button>
          <button mat-menu-item (click)="logout()"><span class="title-btn">Sair</span></button>
        </mat-menu>
      </span>
    </section>
  </div>
  <mat-accordion>
    <mat-expansion-panel hideToggle [expanded]="panelOpenState" (opened)="panelExpansionOpened()"
    (closed)="panelExpansionClosed()" disabled="{{loadingService.isLoading | async}}">
      <form class="c-filter-map" [formGroup]="formFilter">
          <img src="assets/img/clean-filter-icon.svg" id="clean-filter" alt="clean" (click)="clearFilterBtn(this.formFilter.value)">
          <div class="c-filter-map__selects">
            <!--Using items input-->
            <div class="c-filter-group">
              <label class="c-filter-group__label"> Classes de Alertas </label>
              <ng-select placeholder="{{ formFilter.value.class.length ? '' : 'Todos' }}" appendTo="body"
              formControlName="class" [multiple]="true" [closeOnSelect]="false" (change)="filterChange($event)">
                <ng-template ng-multi-label-tmp let-items="items">
                  <span class="selected-options">{{ formFilter.value.class.length }}</span>
                </ng-template>
                <ng-option disabled *ngIf="alertClasses?.length > 1" [value]="''">Todos</ng-option>
                <ng-option *ngFor="let item of alertClasses" [value]="item.type">
                  {{item.type}} &nbsp;
                  <span style="color: gray;font-size: small;">({{item.source}})</span>
                </ng-option>
              </ng-select>
            </div>
            <div class="c-filter-group">
              <label class="c-filter-group__label"> Terra Indígena </label>
              <ng-select placeholder="{{ formFilter.value.ti.length ? '' : 'Todos' }}" appendTo="body"
              formControlName="ti" [multiple]="true" [closeOnSelect]="false" (change)="filterChange($event)"
              [compareWith]="compareFilter">
                <ng-template ng-multi-label-tmp let-items="items">
                  <span class="selected-options">{{ formFilter.value.ti.length }}</span>
                </ng-template>
                <ng-option disabled *ngIf="featuresTi?.length > 1" [value]="''">Todos</ng-option>
                <ng-option *ngFor="let feature of featuresTi" [value]="feature">{{feature.name}}</ng-option>
              </ng-select>
            </div>
            <div class="c-filter-group">
              <label class="c-filter-group__label"> Municípios </label>
              <ng-select placeholder="{{ formFilter.value.city.length ? '' : 'Todos' }}" appendTo="body" groupBy="categoria" [selectableGroupAsModel]="false"
              formControlName="city" [multiple]="true" [closeOnSelect]="false" (change)="filterChange($event)" [selectableGroup]="true"
              [compareWith]="compareFilter">
                <ng-template ng-multi-label-tmp let-items="items">
                  <span class="selected-options">{{ formFilter.value.city.length }}</span>
                </ng-template>
                <ng-option disabled *ngIf="cities?.length > 1" [value]="''">Todos</ng-option>
                <ng-option *ngFor="let city of cities" [value]="city">
                  {{city.name}}
                </ng-option>
              </ng-select>
            </div>
            <div class="c-filter-group">
              <label class="c-filter-group__label"> Cruzamentos Territoriais </label>
              <ng-select placeholder="{{ formFilter.value.vector.length ? '' : 'Todos' }}" appendTo="body" groupBy="categoria" [selectableGroupAsModel]="false"
              formControlName="vector" [multiple]="true" [closeOnSelect]="false" (change)="filterChange($event)" [selectableGroup]="true"
              [compareWith]="compareFilter">
                <ng-template ng-multi-label-tmp let-items="items">
                  <span class="selected-options">{{ formFilter.value.vector.length }}</span>
                </ng-template>
                <ng-option disabled *ngIf="vectorLayers?.length > 1" [value]="''">Todos</ng-option>
                <ng-option *ngFor="let item of vectorLayers" [value]="item">
                  {{item.name.charAt(0) === "0" ? item.name.substring(1) : item.name}}
                </ng-option>
              </ng-select>
            </div>
            <div class="c-filter-group">
              <label class="c-filter-group__label"> Origem do Alerta </label>
              <ng-select placeholder="{{ formFilter.value.source.length ? '' : 'Todos' }}" appendTo="body"
              formControlName="source" [multiple]="true" [closeOnSelect]="false" (change)="filterChange($event)">
                <ng-template ng-multi-label-tmp let-items="items">
                  <span class="selected-options">{{ formFilter.value.source.length }}</span>
                </ng-template>
                <ng-option disabled *ngIf="alertSources?.length > 1" [value]="''">Todos</ng-option>
                <ng-option *ngFor="let item of alertSources" [value]="item.source">
                  {{item.source == 'ALERT' ?
                  'SMDK':item.source}}
                </ng-option>
              </ng-select>
            </div>
            <div class="c-filter-group border-area" style="display: flex; align-items: center;">
              <label class="c-filter-group__label"> Id do Alerta </label>
              <span matPrefix id="arrow-left" (click)="getPreviousAlertId()">
                <img src="assets/img/arrow.svg">
              </span>
              <input id="test-img" class="border-area-alert-id " #alertUnique type="text"
                  formControlName="alertId" labelForId="alertId">
              <span matSuffix id="arrow-right" (click)="getNextAlertId()">
                <img src="assets/img/arrow.svg">
              </span>
            </div>
          </div>
          <div class="c-filter-map__selects">
            <!--Using items input-->
            <div class="c-filter-group">
              <label class="c-filter-group__label"> Área Embargada </label>
              <ng-select placeholder="Todos" appendTo="body" formControlName="embargoed"
                (change)="filterChange($event)">
                <ng-option [value]="''">Todos</ng-option>
                <ng-option [value]="'Sim'">Sim</ng-option>
                <ng-option [value]="'Não'">Não</ng-option>
              </ng-select>
            </div>
            <div class="c-filter-group">
              <label class="c-filter-group__label"> Autorização </label>
              <ng-select placeholder="Todos" appendTo="body" formControlName="authorized"
                (change)="filterChange($event)">
                <ng-option [value]="''">Todos</ng-option>
                <ng-option [value]="'Sim'">Sim</ng-option>
                <ng-option [value]="'Não'">Não</ng-option>
              </ng-select>
            </div>
            <div class="c-filter-group">
              <label class="c-filter-group__label"> Validação </label>
              <ng-select placeholder="Todos" appendTo="body" formControlName="validation"
                (change)="filterChange($event)">
                <ng-option [value]="''">Todos</ng-option>
                <ng-option [value]="'Sim'">Sim</ng-option>
                <ng-option [value]="'Não'">Não</ng-option>
              </ng-select>
            </div>
            <div class="c-filter-group">
              <label class="c-filter-group__label"> Região de Buffer </label>
              <ng-select placeholder="TI (sem buffer)" appendTo="body" formControlName="buffer"
                (change)="filterChange($event)">
                <ng-option [value]="0"> TI (sem buffer) </ng-option>
                <ng-option [value]="3"> Buffer 3km </ng-option>
                <ng-option [value]="5"> Buffer 5km </ng-option>
                <ng-option [value]="10"> Buffer 10km </ng-option>
              </ng-select>
            </div>
            <div class="c-filter-group">
              <label class="c-filter-group__label"> Tipo de Sensor </label>
              <ng-select placeholder="Todos" groupBy="categoria" formControlName="satellite" appendTo="body"
                (change)="filterChange($event)">
                <ng-option *ngIf="alertSatellite?.length > 1" [value]="''">Todos</ng-option>
                <ng-option *ngFor="let item of alertSatellite" [value]="item.type_satellite">{{item.type_satellite}}</ng-option>
              </ng-select>
            </div>
            <div class="c-filter-group border-area alert-size-slider">
              <label class="c-filter-group__label"> Tamanho do Alerta (ha) </label>
              <ng-container *ngIf="alertSizes">
                  <ngx-slider [(value)]="sizeRangeSliderMinValue" [(highValue)]="sizeRangeSliderMaxValue"
                    [options]="sizeRangeSliderOpts" formControlName="size" (userChange)="filterChange($event)">
                  </ngx-slider>
              </ng-container>
            </div>
          </div>
        </form>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="exp-position-center">
    <div class="expansion-arrow-opened" (click)="panelExpansionClosed()" *ngIf="panelOpenState"></div>
    <div class="expansion-arrow-closed" (click)="panelExpansionOpened()" *ngIf="!panelOpenState"></div>
  </div>
</header>
