import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { onClickMarginTop } from 'src/app/pages/page-events';
import { onMapInspectorClick, onMapInspectorClosed } from '../map.events';
import { onStatisticEvent } from '../statistic/statistic.events';
import { Geometry } from '@turf/turf';

@Component({
  selector: 'app-point-inspector',
  templateUrl: './point-inspector.component.html',
  styleUrls: ['./point-inspector.component.scss']
})
export class PointInspectorComponent implements OnInit {
  @ViewChild('inspectorPanel') inspectorPanel: ElementRef;
  el: ElementRef;

  panelPointInspectorExpansion = false
  panelOpenState = false;
  statusHeader = 'CLOSE';
  data: any[] = [];
  pointInfo: any = {};
  pointHistory: any = null;
  pointFireHistory: any = null;
  lat: number = 0;
  lng: number = 0;
  dataLength: number;

  constructor(
    private apiService: ApiService,
    private renderer: Renderer2,
    el: ElementRef
  ) {
    this.el = el;
    // this.renderer.listen('window', 'click', (e: Event) => {
    //   if (this.data.length > 0 && !this.inspectorPanel.nativeElement.contains(e.target)) {
    //     this.close()
    //   }
    // });
  }

  ngOnInit(): void {
    onMapInspectorClick.subscribe(data => {
      localStorage.setItem('dev_data_info', JSON.stringify(data));
      this.dataLength = Object.keys(data).length;
      this.pointInfo = data.info;
      this.lat = data.lat;
      this.lng = data.lng;
      this.pointHistory = data.history;
      this.pointFireHistory = data.fireHistory;
      console.log(this.pointInfo);
      console.log(this.pointHistory);
    })

    onClickMarginTop.subscribe((action) => {
      // console.log(action)
      this.statusHeader = action;
    })

    onStatisticEvent.subscribe((data) => {
      this.panelPointInspectorExpansion = data.expansion;
    })
  }

  close() {
    this.data = [];
    this.dataLength = 0;
    onMapInspectorClosed.emit();
  }

  ngAfterViewInit() {
    const hostElem = this.el.nativeElement;
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.data.length > 0 && hostElem.parentNode.contains(e.target) && !hostElem.parentNode.children[1].contains(e.target) &&  !this.inspectorPanel.nativeElement.contains(e.target)) {
        this.close()
      }
    });
  }
}
