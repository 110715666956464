import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminAuthGuard, AuthGuard } from './auth.guard';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { MapLayoutComponent } from './layouts/map-layout/map-layout.component';
import { PageLayoutComponent } from './layouts/page-layout/page-layout.component';
import { UserLayoutComponent } from './layouts/user-layout/user-layout.component';
import { AlertDetailComponent } from './modules/map/alerts/alert-detail/alert-detail.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: MapLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'alerts/:id',
        component: AlertDetailComponent
      },
    ],
  },
  {
    path: 'login',
    component: LoginLayoutComponent,
    children: [
      {
        path: '',
        component: LoginComponent
      },
    ],
  },
  {
    path: 'users',
    component: UserLayoutComponent,
    children: [
      {
        path: '',
        canActivate: [AdminAuthGuard],
        loadChildren:()=> import('./../app/modules/users/users.module').then(m => m.UsersModule)
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
