import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ServicesModule } from 'src/app/services/services.module';
import { PopupContentComponent } from './popup-content.component';
import { BtnControllComponent } from './btn-controll/btn-controll.component';
import { MapComponent } from './map.component';
import { MaterialModule } from 'src/app/shared/material.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { StatisticComponent } from './statistic/statistic.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertDetailComponent } from './alerts/alert-detail/alert-detail.component';
import { ChartsModule } from '../charts/charts.module';
import { BaseLayerComponent } from './baselayer/baselayer.component';
import { OverLayerComponent } from './overlayer/overlayer.component';
import { SubtitlesComponent } from './subtitles/subtitle.component';
import { SearchLocalizationDialogComponent } from './search-localization/search-localization-dialog.component';
import { SearchLocalizationComponent } from './search-localization/search-localization.component';
import { PointInspectorComponent } from './point-inspector/point-inspector.component';
import { SidenavToggleButtonComponent } from './sidenav/sidenav-toggle-button/sidenav-toggle-button.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { PointInspectorChartComponent } from './point-inspector/point-inspector-chart/point-inspector-chart.component';
import { PlanetImageSliderComponent } from './planet-image-slider/planet-image-slider.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ServicesModule,
    SharedModule,
    MaterialModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    MatSidenavModule,
    MatListModule,
    NgxSliderModule
  ],
  declarations: [
    MapComponent,
    SidenavComponent,
    PopupContentComponent,
    BtnControllComponent,
    StatisticComponent,
    AlertDetailComponent,
    BaseLayerComponent,
    OverLayerComponent,
    SubtitlesComponent,
    SearchLocalizationComponent,
    SearchLocalizationDialogComponent,
    PointInspectorComponent,
    PointInspectorChartComponent,
    SidenavToggleButtonComponent,
    PlanetImageSliderComponent
  ],
  exports: [
    MapComponent,
    SidenavComponent,
    AlertDetailComponent,
    BaseLayerComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents:[
    PopupContentComponent
  ]
})
export class MapModule { }
