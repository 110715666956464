// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

export const BASE         = "http://"
export const BASE_S       = "https://"
export const BASE_PORT    = "9001"
// export const BASE_PORT    = "9001" //emergencial

export const BASE_URL     = `${BASE_S}kaninde.solved.eco.br`;
// export const BASE_URL     = `${BASE_S}kaninde.solved.eco.br`; //emergencial

export const BASE_URL_GEO = `${BASE_S}kaninde.solved.eco.br`;


export const URL_FILE               = `${BASE_URL}:${BASE_PORT}/files`;

export const URL_API                = `${BASE_URL}:${BASE_PORT}/api`;

export const URL_ASSETS             = `${BASE_URL}:${BASE_PORT}/assets`;

export const URL_VALIDATION_MODULE  = `${BASE_URL}:${BASE_PORT}/map`;

export const URL_UPLOAD             = `${BASE_URL}:${BASE_PORT}/layers`;

export const URL_DASHBOARD          = `${BASE_URL}:${BASE_PORT}/dashboard`;

export const URL_GEO_API            = `${BASE_URL_GEO}:3000`;
// export const URL_GEO_API            = `${BASE_URL_GEO}:3000`; //emergencial

export const GEOSERVER_URL          = `${BASE_URL_GEO}:9090/geoserver`;

export const PLANET_API_KEY = 'PLAK57ac7d05297a42e3ad50cb8bfba37c75';

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 * import 'zone.js/dist/zone-error';  // Included with Angular CLI.
 */
